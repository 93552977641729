















































































































import Vue from "vue";
import _ from "lodash";
import store from "../../store/shop";
import ShopAPI from "../../common/api/api_shop";
import { TranslateServiceBuilder } from "../../services/Translate/translateServiceBuilder";
import notificationMixin from "../../mixin/notification";

const translate = TranslateServiceBuilder.Instance()._;
const api = new ShopAPI();

Vue.directive('enhance-defaulot-value', {
    bind: function (el) {
        el.addEventListener('focus', function() {
            const value = el.value;
            if (value == 0) {
                el.value = '';
            }
        });
        el.addEventListener('blur', function() {
            const value = el.value;
            if (value == '') {
                el.value = 0;
            }
        });
    }
});

export default Vue.extend({
  components: {
    QTranslate: () => import("../../components/elements/QTranslate.vue"),
    QButton: () => import("../../components/elements/QButton.vue"),
  },
  mixins: [notificationMixin],
  props: {
    isOpen: {
      type: Boolean,
      required: true,
      default: false,
    },
    seatId: {
      required: true,
    },
  },
  data() {
    return {
      publish: {
        grow: 0,
        child: 0,
      },
      isOpenSuccessPopup: false,
    };
  },
  mounted() {
    api.set_token(store.state.token);
  },
  watch: {
    isOpen() {
      this.resetForm();
    //   this.$refs.focus_first_publish_grow.focus();
    },
  },
  computed: {
    show() {
      return this.isOpen ? "checked" : "";
    },
    showSuccessPopup() {
      return this.isOpenSuccessPopup ? "checked" : "";
    },
    prefix() {
      return this.$route.params.prefix;
    },
    shoppix() {
      return this.$route.params.shoppix;
    },
    isInvalidInput(): boolean {
      const grow = parseInt(this.publish.grow);
      const child = parseInt(this.publish.child);
      const total = grow + child;
      if (_.isNaN(total) || grow < 0 || child < 0) {
        return true;
      }
      if (total === 0) {
        return true;
      }

      return false;
    }
  },
  methods: {
    closeSuccessPopup() {
      this.isOpenSuccessPopup = false;
    },
    resetForm() {
      this.publish = {
        grow: 0,
        child: 0,
      };
    },
    handleClickSubmit: function () {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
      // this.submitItem();
      api
        .checkIn({
          seat_id: this.seatId,
          quantities: parseInt((this.publish.child || 0)) + parseInt(this.publish.grow),
          quantity_child: this.publish.child || 0,
        })
        .then((response) => {
          if (response.data.status === "error") {
            this.showErrorNotification(response.data.message);
          } else {
            this.$emit("update");
            this.isOpenSuccessPopup = true;
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    closeEditor: function () {
      this.isLoading = false;
      this.$emit("close");
    },
  },
  filters: {},
});
